$( document ).ready(function() {

  $('.listado').click(function(e){

    var id = $(this).data('rel');
    if($('#'+id).hasClass('desc_ativo')){
      $('#'+id).removeClass('desc_ativo').css("display","none");
      $(this).find('img').css("transform","rotate(0deg)");
    }else{
      $('#'+id).addClass('desc_ativo').css("display","table-row");
      $(this).find('img').css("transform","rotate(180deg)");
    }

  });

  $('.form-button').click(function(event){
      event.stopPropagation();
  });

  $("#fechar").click(function(){
    $(".banner-flutuante").css("display","none");
  });



});
